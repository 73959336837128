import {Route, Router} from "preact-router";
import {Main} from "../routes/Main/Main";
import TiresWrapper from "../routes/Tires";
import CampaignPage from "../routes/Campaigns";
import {Feature} from "../routes/Feature/Feature";
import Sales from "../routes/Sales";
import {Paths, StaticPaths} from "../data/routing";
import {WinterCompare} from "../routes/Static/Compare/WinterCompare";
import {SummerCompare} from "../routes/Static/Compare/SummerCompare";
import {WinterSizes} from "../routes/Static/Sizes/WinterSizes";
import {SummerSizes} from "../routes/Static/Sizes/SummerSizes";
import {h} from "preact";

export const Routes = () => {
    return (
        <Router>
            <Route path={Paths.Main} component={Main} />
            <Route path={Paths.Tires} component={TiresWrapper} />
            <Route path={Paths.Campaign} component={CampaignPage} />
            <Route path={Paths.Feature} component={Feature} />
            <Route path={Paths.Sales} component={Sales} />
            <Route path={StaticPaths.WinterCompare} component={WinterCompare} />
            <Route path={StaticPaths.SummerCompare} component={SummerCompare} />
            <Route path={StaticPaths.WinterSizes} component={WinterSizes} />
            <Route path={StaticPaths.SummerSizes} component={SummerSizes} />
        </Router>
    )
}