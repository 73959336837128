import {h} from "preact";
import {tires} from "../../data/tires/tiresData";
import {IFeature} from "../../models/tire";
import classes from './feature.module.scss';
import commonClasses from '../../style/common.module.scss';
import {PageHeader} from "../../components/PageHeader/PageHeader";
import {Link, route} from "preact-router";
import {tg} from "../../telegram";
import {Paths} from "../../data/routing";
import {Button} from "../../components/Button/Button";

interface IFeatureProps {
    tireId: string;
    featureId: string;
}

export const Feature = ({ tireId, featureId }: IFeatureProps) => {
    const tire = tires[tireId];
    if (!tire) {
        route(Paths.Main);
        return null;
    }
    const feature = tire.features.find(({ id }) => id === featureId) as IFeature;
    if (!feature) {
        route(Paths.Main);
        return null;
    }
    const youtubeLinks = feature.youtubeLinks;
    const backLink = `/tires/${tireId}/details`;

    const onLinkClick = (event: MouseEvent, link: string) => {
        event.preventDefault();
        event.stopPropagation();
        tg.WebApp.openLink(link);
    }

    return (
        <div class={commonClasses.container}>
            <PageHeader backLink={backLink} header={feature.name} />
            <img className={classes.featureImage} src={`/assets/feature/${feature.image}`} />
            <div dangerouslySetInnerHTML={{ __html: feature.content }} />
            {!!youtubeLinks && youtubeLinks.length === 1 && (
                <Link
                    href={youtubeLinks[0]}
                    class={classes.youtubeLink}
                    onClick={(event) => onLinkClick(event, youtubeLinks[0])}
                >
                    <Button>
                        Ссылка на YouTube
                    </Button>
                </Link>
            )}
            {!!youtubeLinks && youtubeLinks.length > 1 && youtubeLinks.map((link, index) => (
                <Link
                    key={link}
                    href={link}
                    class={classes.youtubeLink}
                    onClick={(event) => onLinkClick(event, link)}
                >
                    <Button>
                        Ссылка на YouTube #{index + 1}
                    </Button>
                </Link>
            ))}
        </div>
    );
}