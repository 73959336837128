import {openFile} from "../../utils/openFile";
import commonClasses from "../../style/common.module.scss";
import {PageHeader} from "../PageHeader/PageHeader";
import {Paths} from "../../data/routing";
import {h} from "preact";

interface IShowFilesProps {
    fileNames: Array<string>;
    header: string;
}

export function ShowFiles ({ header, fileNames }: IShowFilesProps) {
    const links = fileNames.map(fileName => ({
        preview: `${fileName}.webp`,
        file: `${fileName}.jpg`,
    }))

    const onImgClick = (link: string) => {
        openFile(link);
    };

    return (
        <div class={commonClasses.container}>
            <PageHeader backLink={Paths.Main} header={header} />
            {links.map(link => (
                <img
                    class={commonClasses.wholeWidth}
                    key={link}
                    src={link.preview}
                    onClick={() => onImgClick(link.file)}
                />
            ))}
        </div>
    )
}

export const getShowFilesComponent = (header: string, fileNames: Array<string>) => () => (
    <ShowFiles header={header} fileNames={fileNames} />
);