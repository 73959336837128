import {h} from "preact";
import {Button} from "../Button/Button";
import classes from './seasonToggle.module.scss';
import {Season, SeasonLabels} from "../../utils/season";

const seasons: [Season, Season] =  ['summer', 'winter'];
const defaultSeasonLabels: SeasonLabels = {
    summer: 'Лето',
    winter: 'Зима',
}

interface ISeasonToggle {
    activeSeason: Season;
    onActiveSeasonChange: (newActiveSeason: Season) => void;
    seasonLabels?: SeasonLabels;
}

export function SeasonToggle({
    activeSeason,
    onActiveSeasonChange,
    seasonLabels = defaultSeasonLabels,
}: ISeasonToggle) {
    return (
        <div className={classes.seasons}>
            {seasons.map(season => (
                <Button
                    key={season}
                    variant={activeSeason === season ? 'primary' : 'secondary'}
                    onClick={activeSeason === season ? undefined : () => onActiveSeasonChange(season)}
                >
                    {seasonLabels[season]}
                </Button>
            ))}
        </div>
    )
}