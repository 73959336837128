import {h} from "preact";
import {Link} from "preact-router";
import commonClasses from '../../style/common.module.scss';
import {campaignOrder, campaigns} from "../../data/campaignsData";
import {Button} from "../../components/Button/Button";
import classes from './campaignList.module.scss';
import clsx from "clsx";

export function CampaignsList () {
    return (
           <div>
               {campaignOrder.map(campaignId => (
                   <Link
                       key={campaignId}
                       class={clsx(commonClasses.listItem, classes.campaignLink)}
                       href={`/campaigns/${campaignId}`}
                   >
                       <img src={`/assets/campaign/${campaigns[campaignId].icon}`} />
                       <Button >
                           {campaigns[campaignId].name}
                       </Button>
                   </Link>
               ))}
           </div>
    )
}