import {getShowFilesComponent} from "../../../components/ShowFiles/ShowFiles";

const prefix = '/assets/files/compare/winter';

export const WinterCompare = getShowFilesComponent('Зимние сравнительные таблицы', [
    `${prefix}/для внедорожников шипованные зима`,
    `${prefix}/для внедорожников нешипованные зима`,
    `${prefix}/для легковых нешипованные зима`,
    `${prefix}/для легковых шипованные зима`,
    `${prefix}/для мягкой зимы`,
]);