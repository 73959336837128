import {h} from "preact";
import './loading.module.scss';
export function Loading() {
    return (
        <div class="cds--loading">
            <svg className="cds--loading__svg" viewBox="0 0 100 100">
                <circle className="cds--loading__stroke" cx="50" cy="50%" r="44" />
            </svg>
        </div>
    )
}