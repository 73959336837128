import {Fragment, h} from 'preact';
import {tg} from "../telegram";
import {Routes} from "./Routes";
import {useState} from "react";
import {useEffect} from "preact/hooks";
import axios from "axios/index";
import {logUrl} from "../utils/constants";
import {AxiosError} from "axios";
import {Loading} from "./Loading/Loading";
import classes from './app.module.scss';

if (typeof window !== 'undefined') {
    tg.WebApp.expand();
}

export const App = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            return;
        }

        setIsLoading(true);
        axios.get(`${logUrl}/log`, {
            params: {
                key: tg.WebApp.initData,
            },
        }).then(() => {
            setErrorMessage(null);
        }).catch((e) => {
            console.info(e);
            if (
                e instanceof AxiosError
                && !!e?.response?.data
                && e.response.status === 401
                && 'message' in e.response.data
            ) {
                setErrorMessage(e.response.data.message);
            } else {
                setErrorMessage(null);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [])


    return (
        <div id="app">
            {isLoading ? (
                <div class={classes.center}>
                    <Loading />
                </div>
            ) : (
             <Fragment>
                 {errorMessage ? (
                     <div class={classes.center}>
                         <p class={classes.errorMessage}>
                             {errorMessage}
                         </p>
                     </div>
                 ) : (
                     <Routes />
                 )}
             </Fragment>
            )}
        </div>
    );
}
