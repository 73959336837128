import {h} from "preact";
import {BackButton} from "../BackButton/BackButton";
import {Link} from "preact-router";
import {useBackButton, useScrollToTop} from "../../utils/hooks";
import classes from './pageHeader.module.scss';
import {getUrlParts} from "../../utils/url";

interface IPageHeaderProps {
    backLink: string;
    header: string;
}

export function PageHeader({ backLink, header }: IPageHeaderProps) {
    const { from } = getUrlParts();
    const activeBackLink = from || backLink;

    useBackButton(activeBackLink)
    useScrollToTop();

    return (
        <header class={classes.header}>
            <Link href={activeBackLink}>
                <BackButton />
            </Link>
            <h3>{header}</h3>
        </header>
    )
}