import {useEffect} from "preact/hooks";
import {hideBackButton, showBackButton} from "../telegram";

export function useBackButton(link: string) {
    useEffect(() => {
        const backButtonCallback = showBackButton(link);

        return () => {
            hideBackButton(backButtonCallback);
        }
    }, []);
}

export function useScrollToTop() {
    useEffect(() => {
        scroll(0, 0);
    }, []);
}