import {getShowFilesComponent} from "../../../components/ShowFiles/ShowFiles";

const prefix = '/assets/files/sizes/winter';

export const WinterSizes = getShowFilesComponent('Зимние типоразмеры', [
    `${prefix}/для внедорожников - 1 зима`,
    `${prefix}/для внедорожников - 2 зима`,
    `${prefix}/для легковых автомобилей - 1 зима`,
    `${prefix}/для легковых автомобилей - 2 зима`,
    `${prefix}/для коммерческого транспорта зима`,
    `${prefix}/для мягкой зимы`,

])