import {Fragment, h} from "preact";
import {Button} from "../Button/Button";
import axios, {AxiosError} from "axios";
import {baseUrl} from "../../utils/constants";
import {useState} from "preact/compat";
import {Loading} from "../Loading/Loading";
import {tg} from "../../telegram";
import classes from './getRegion.module.scss';

interface IManagerInfo {
    name: string;
    phone: string;
}

const defaultErrorMessage = `
Произошла ошибка во время запроса.
Пожалуйста, проверьте интернет подключение и попробуйте перезапустить приложение 
Если ошибка повторяется - обратитесь к менеджеру.
`;

interface ICoordinates {
    latitude: number;
    longitude: number;
}

export function GetRegion() {
    const [managersData, setManagersData] = useState<Array<IManagerInfo> | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            const coordinates: ICoordinates = await new Promise((resolve, reject) => {
                window.navigator.geolocation.getCurrentPosition((position) => {
                    resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                }, (error) => {
                    reject(error);
                })
            });
            const {data} = await axios.get<Array<IManagerInfo>>(`${baseUrl}/geolocation`, {
                params: {
                    key: tg.WebApp.initData,
                    ...coordinates,
                },
            });
            setManagersData(data);
        } catch (e) {
            if (e instanceof AxiosError && e.response && e.response.data?.message) {
                setErrorMessage(e.response.data.message);
            } else if (e instanceof GeolocationPositionError) {
                if (e.code === e.PERMISSION_DENIED) {
                    setErrorMessage('К сожалению мы не сможем узнать какой у вас региональный менеджер без доступа к геолокации');
                } else if (e.code === e.TIMEOUT) {
                    setErrorMessage('Время ожидания разрешения истекло. Пожалуйста, попробуйте ещё раз');
                } else {
                    setErrorMessage('Не удалось получить локацию. Пожалуйста, проверьте что доступ к геолокации разрешен для Телеграм');
                }
            } else {
                setErrorMessage(defaultErrorMessage)
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            {managersData === null && !isLoading && (
                <Button onClick={loadData}>
                    Связаться с сотрудником Ikon Tyres
                </Button>
            )}
            {errorMessage && (
                <p class={classes.errorMessage}>{errorMessage}</p>
            )}
            {isLoading && (
                <div class={classes.loaderWrapper}>
                    <Loading />
                </div>
            )}
            {managersData && (
                <Fragment>
                    <h4>
                        Региональные представители:
                    </h4>
                    <ul>
                        {managersData.map(manager => (
                            <li key={manager.phone}>
                                {manager.name}: {manager.phone}
                            </li>
                        ))}
                    </ul>
                </Fragment>
            )}
        </Fragment>

    )

}