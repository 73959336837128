import {route} from "preact-router";

export const tg = Telegram;

type Callback = () => void;

interface MainButtonProps {
    text: string;
    callback: Callback;
}

export function showMainButton(props: MainButtonProps) {
    tg.WebApp.MainButton.setText(props.text);
    tg.WebApp.MainButton.onClick(props.callback);
    tg.WebApp.MainButton.show();
}

export function hideMainButton(callback: Callback) {
    tg.WebApp.MainButton.hide();
    tg.WebApp.MainButton.offClick(callback);
}

export function showBackButton(url: string): Callback {
    tg.WebApp.BackButton.show();
    const callback = () => route(url)
    tg.WebApp.BackButton.onClick(callback);
    return callback;
}

export function hideBackButton(callback: Callback) {
    tg.WebApp.BackButton.hide();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    tg.WebApp.BackButton.offClick(callback);
}