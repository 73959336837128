import {Fragment, h} from "preact";
import {Link} from "preact-router";
import {tires, winterTiresOrder, summerTiresOrder } from "../../data/tires/tiresData";
import classes from './tiresList.module.scss';
import {useSeason} from "../../utils/season";
import {SeasonToggle} from "../../components/SeasonToggle/SeasonToggle";

export function TiresList() {
    const { season: activeSeason, setSeason } = useSeason();
    const tiresOrder = activeSeason === 'winter' ? winterTiresOrder : summerTiresOrder;

    return (
        <Fragment>
            <SeasonToggle 
                activeSeason={activeSeason} 
                onActiveSeasonChange={setSeason}
                seasonLabels={{ winter: 'Зимние шины', summer: 'Летние шины' }}
            />
            <p>
                Нажми на шину чтобы узнать о ней больше.
            </p>
            <div class={classes.list}>
                {tiresOrder.map(tireId => (
                    <Link key={tireId} class={classes.listItem} href={`/tires/${tireId}/details`}>
                        <img class={classes.tireIcon} src={`/assets/tire/${tires[tireId].icon}`} />
                        <h4>{tires[tireId].name}</h4>
                    </Link>
                ))}
            </div>
        </Fragment>
    )
}