import {useState} from "react";

function isWinter(): boolean {
    const today = new Date();
    const month = today.getMonth() + 1;
    return month >= 8 || month < 2;
}

export type Season = 'winter' | 'summer';

export type SeasonLabels = Record<Season, string>;

export const currentSeason: Season = isWinter() ? 'winter' : 'summer';


export function useSeason() {
    const [season, setSeason] = useState<Season>(currentSeason);

    return {season, setSeason};
}