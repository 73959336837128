import {Fragment, h} from "preact";
import {Button} from "../../components/Button/Button";
import {Link} from "preact-router";
import {TiresList} from "./TiresList";
import commonClasses from '../../style/common.module.scss'
import classes from './main.module.scss';
import clsx from "clsx";
import {Paths, StaticPaths} from "../../data/routing";
import {useScrollToTop} from "../../utils/hooks";
import {CampaignsList} from "./CampaignsList";
import {openFile} from "../../utils/openFile";
import {GetRegion} from "../../components/GetRegion/GetRegion";

export function Main() {
    useScrollToTop();
    return (
        <div class={clsx(commonClasses.container, classes.mainWrapper)}>
            <div class={classes.description}>
                <h3>
                    Шины
                </h3>
                <TiresList />
            </div>
            <hr />
            {/* eslint-disable-next-line no-constant-condition */}
            {true ? (
                <div class={classes.description}>
                    <h3>Типоразмеры и Cравнительные таблицы</h3>
                    <div class={classes.warning}>
                        Обновленная информация будет добавлена в ближайшее время
                    </div>
                </div>
            ) : (
                <Fragment>
                    <div className={classes.description}>
                        <h3>Типоразмеры</h3>
                        <div className={classes.actions}>
                            <Link href={StaticPaths.WinterSizes}>
                                <Button>
                                    Зимние шины
                                </Button>
                            </Link>
                            <Link href={StaticPaths.SummerSizes}>
                                <Button>
                                    Летние шины
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <div className={classes.description}>
                        <h3>Сравнительные таблицы</h3>
                        <div className={classes.actions}>
                            <Link href={StaticPaths.WinterCompare}>
                                <Button>
                                    Зимние шины
                                </Button>
                            </Link>
                            <Link href={StaticPaths.SummerCompare}>
                                <Button>
                                    Летние шины
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Fragment>
            )}
            <hr />
            <div class={classes.description}>
                <h3>Брошюры</h3>
                <div class={classes.actions}>
                    <Button onClick={() => openFile('/assets/files/brochure/Брошюра_Зима.pdf')}>
                        Зимние шины
                    </Button>
                    <Button disabled>
                        Летние шины
                    </Button>
                </div>
                <div class={classes.warning}>
                    Информация по летним шинам будет добавлена в ближайшее время
                </div>
            </div>
            <hr />
            <div class={classes.description}>
                <h3>Акции</h3>
                <p>
                    Узнай об актуальных акциях, действующих на шины Ikon Tyres.
                </p>
                <CampaignsList />
            </div>
            <hr />
            {false && (
                <div className={classes.description}>
                    <h3>
                        Техника продаж
                    </h3>
                    <p>
                        Узнай о технике продаж шин Ikon Tyres 5П.
                    </p>
                    <Link class={classes.link} href={Paths.Sales}>
                        <Button>
                            Техника продаж
                        </Button>
                    </Link>
                </div>
            )}
            <GetRegion />
        </div>
    )
}