export enum Paths {
    Main = '/',
    Campaign = '/campaigns/:id',
    Sales = '/sales',
    Tires = '/tires/:id/:rest',
    Feature = '/feature/:tireId/:featureId',
}

export enum StaticPaths {
    WinterCompare = '/static/compare/winter',
    SummerCompare = '/static/compare/summer',
    WinterSizes = '/static/sizes/winter',
    SummerSizes = '/static/sizes/summer',
}

export enum TiresPaths {
    TireDetails = '/tires/:id/details',
    TireWins = '/tires/:id/wins',
    TireSizes = '/tires/:id/sizes',
}