import summerTiresJSON from "./summer.tires.json";
import winterTiresJSON from "./winter.tires.json";
import summerTiresOrderJSON from "./summer.tiresOrder.json";
import winterTiresOrderJSON  from "./winter.tiresOrder.json";
import {ITire} from "../../models/tire";

export const summerTiresOrder: Array<string> = summerTiresOrderJSON;
export const winterTiresOrder: Array<string> = winterTiresOrderJSON;

export const tires: Record<string, ITire> = {
    ...summerTiresJSON,
    ...winterTiresJSON,
};
