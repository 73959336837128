import {FunctionalComponent, h} from "preact";
import classes from './button.module.scss';
import clsx from "clsx";

type ButtonProps = h.JSX.HTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary'
}

export const Button: FunctionalComponent<ButtonProps> = ({ children, variant, className,  ...rest }) => {
    const chosenVariant = variant || 'primary';
    const resultClassName = chosenVariant === 'primary'
        ? clsx(classes.button, 'cds--btn--primary')
        : clsx(classes.buttonSecondary, 'cds--btn--secondary')


    return (
        <button class={clsx('cds--btn', className, resultClassName)} {...rest}>
            {children}
        </button>
    )
}